export const USER_REGISTERByADMIN_REQUEST = 'USER_REGISTERByADMIN_REQUEST'
export const USER_REGISTERByADMIN_SUCCESS = 'USER_REGISTERByADMIN_SUCCESS'
export const USER_REGISTERByADMIN_FAIL = 'USER_REGISTERByADMIN_FAIL'
export const USER_REGISTERByADMIN_RESET = 'USER_REGISTERByADMIN_RESET'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const SEND_RESET_SMS_TOR_REQUEST = 'SEND_RESET_SMS_TOR_REQUEST'
export const SEND_RESET_SMS_TOR_SUCCESS = 'SEND_RESET_SMS_TOR_SUCCESS'
export const SEND_RESET_SMS_TOR_FAIL = 'SEND_RESET_SMS_TOR_FAIL'
export const SEND_RESET_SMS_TOR_RESET = 'SEND_RESET_SMS_TOR_RESET'

export const RESET_PASSWORD_WITH_PHONE_PAGE_REQUEST =
  'RESET_PASSWORD_WITH_PHONE_PAGE_REQUEST'
export const RESET_PASSWORD_WITH_PHONE_PAGE_SUCCESS =
  'RESET_PASSWORD_WITH_PHONE_PAGE_SUCCESS'
export const RESET_PASSWORD_WITH_PHONE_PAGE_FAIL =
  'RESET_PASSWORD_WITH_PHONE_PAGE_FAIL'

export const RESET_MY_PASSWORD_REQUEST = 'RESET_MY_PASSWORD_REQUEST'
export const RESET_MY_PASSWORD_SUCCESS = 'RESET_MY_PASSWORD_SUCCESS'
export const RESET_MY_PASSWORD_FAIL = 'RESET_MY_PASSWORD_FAIL'

export const RESET_PASSWORD_PAGE_GET_REQUEST = 'RESET_PASSWORD_PAGE_GET_REQUEST'
export const RESET_PASSWORD_PAGE_GET_SUCCESS = 'RESET_PASSWORD_PAGE_GET_SUCCESS'
export const RESET_PASSWORD_PAGE_GET_FAIL = 'RESET_PASSWORD_PAGE_GET_FAIL'

export const RESET_PASSWORD_PAGE_REQUEST = 'RESET_PASSWORD_PAGE_REQUEST'
export const RESET_PASSWORD_PAGE_SUCCESS = 'RESET_PASSWORD_PAGE_SUCCESS'
export const RESET_PASSWORD_PAGE_FAIL = 'RESET_PASSWORD_PAGE_FAIL'

export const ONE_USER_SEARCH_BY_EMAIL_REQUEST =
  'ONE_USER_SEARCH_BY_EMAIL_REQUEST'
export const ONE_USER_SEARCH_BY_EMAIL_SUCCESS =
  'ONE_USER_SEARCH_BY_EMAIL_SUCCESS'
export const ONE_USER_SEARCH_BY_EMAIL_FAIL = 'ONE_USER_SEARCH_BY_EMAIL_FAIL'

export const USER_LOGIN_EMAIL_REQUEST = 'USER_LOGIN_EMAIL_REQUEST'
export const USER_LOGIN_EMAIL_SUCCESS = 'USER_LOGIN_EMAIL_SUCCESS'
export const USER_LOGIN_EMAIL_FAIL = 'USER_LOGIN_EMAIL_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const SAPAR_LIST_REQUEST = 'SAPAR_LIST_REQUEST'
export const SAPAR_LIST_SUCCESS = 'SAPAR_LIST_SUCCESS'
export const SAPAR_LIST_FAIL = 'SAPAR_LIST_FAIL'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const MAKE_WORKINGDAY_REQUEST = 'MAKE_WORKINGDAY_REQUEST'
export const MAKE_WORKINGDAY_SUCCESS = 'MAKE_WORKINGDAY_SUCCESS'
export const MAKE_WORKINGDAY_FAIL = 'MAKE_WORKINGDAY_FAIL'

export const CLOCK_DELETE_ALL_REQUEST = 'MAKE_WORKINGDAY_REQUEST'
export const CLOCK_DELETE_ALL_SUCCESS = 'MAKE_WORKINGDAY_SUCCESS'
export const CLOCK_DELETE_ALL_FAIL = 'MAKE_WORKINGDAY_FAIL'
export const CLOCK_DELETE_ALL_RESET = 'CLOCK_DELETE_ALL_RESET'

export const CLOCK_DELETE_AVILABLE_REQUEST = 'MAKE_WORKINGDAY_REQUEST'
export const CLOCK_DELETE_AVILABLE_SUCCESS = 'MAKE_WORKINGDAY_SUCCESS'
export const CLOCK_DELETE_AVILABLE_FAIL = 'MAKE_WORKINGDAY_FAIL'

export const CLOCK_DELETE_SELECTED_REQUEST = 'CLOCK_DELETE_SELECTED_REQUEST'
export const CLOCK_DELETE_SELECTED_SUCCESS = 'CLOCK_DELETE_SELECTED_SUCCESS'
export const CLOCK_DELETE_SELECTED_FAIL = 'CLOCK_DELETE_SELECTED_FAIL'

export const PICK_WORKINGDAY_REQUEST = 'PICK_WORKINGDAY_REQUEST'
export const PICK_WORKINGDAY_SUCCESS = 'PICK_WORKINGDAY_SUCCESS'
export const PICK_WORKINGDAY_RESET = 'PICK_WORKINGDAY_RESET'
export const PICK_WORKINGDAY_FAIL = 'PICK_WORKINGDAY_FAIL'

export const WORKING_DAYS_REQUEST = 'WORKING_DAYS_REQUEST'
export const WORKING_DAYS_SUCCESS = 'WORKING_DAYS_SUCCESS'
export const WORKING_DAYS_FAIL = 'WORKING_DAYS_FAIL'

export const AVILABLE_WORKINGDAY_TORS_FOR_3_HOURS_TIPUL_LIST_REQUEST =
  'AVILABLE_WORKINGDAY_TORS_FOR_3_HOURS_TIPUL_LIST_REQUEST'
export const AVILABLE_WORKINGDAY_TORS_FOR_3_HOURS_TIPUL_LIST_SUCCESS =
  'AVILABLE_WORKINGDAY_TORS_FOR_3_HOURS_TIPUL_LIST_SUCCESS'
export const AVILABLE_WORKINGDAY_TORS_FOR_3_HOUR_TIPUL_LIST_FAIL =
  'AVILABLE_WORKINGDAY_TORS_FOR_3_HOUR_TIPUL_LIST_FAIL'

export const AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_HALF_TIPUL_LIST_REQUEST =
  'AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_HALF_TIPUL_LIST_REQUEST'
export const AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_HALF_TIPUL_LIST_SUCCESS =
  'AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_HALF_TIPUL_LIST_SUCCESS'
export const AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_HALF_TIPUL_LIST_FAIL =
  'AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_HALF_TIPUL_LIST_FAIL'

export const AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_TIPUL_LIST_REQUEST =
  'AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_TIPUL_LIST_REQUEST'
export const AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_TIPUL_LIST_SUCCESS =
  'AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_TIPUL_LIST_SUCCESS'
export const AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_TIPUL_LIST_FAIL =
  'AVILABLE_WORKINGDAY_TORS_FOR_2_HOURS_TIPUL_LIST_FAIL'

export const LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_REQUEST =
  'LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_REQUEST'
export const LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_SUCCESS =
  'LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_SUCCESS'
export const LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_FAIL =
  'LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_FAIL'
export const LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_RESET =
  'LIST_WORKING_DAYS_FOR_NEXT_7_DAYS_RESET'

export const CREATE_CANCEL_NOTI_REQUEST = 'CREATE_CANCEL_NOTI_REQUEST'
export const CREATE_CANCEL_NOTI_SUCCESS = 'CREATE_CANCEL_NOTI_SUCCESS'
export const CREATE_CANCEL_NOTI_FAIL = 'CREATE_CANCEL_NOTI_FAIL'

export const MAKE_ALL_BE_WATCH_REQUEST = 'MAKE_ALL_BE_WATCH_REQUEST'
export const MAKE_ALL_BE_WATCH_SUCCESS = 'MAKE_ALL_BE_WATCH_SUCCESS'
export const MAKE_ALL_BE_WATCH_FAIL = 'MAKE_ALL_BE_WATCH_FAIL'

export const CANCEL_NOTI_LIST_REQUEST = 'CANCEL_NOTI_LIST_REQUEST'
export const CANCEL_NOTI_LIST_SUCCESS = 'CANCEL_NOTI_LIST_SUCCESS'
export const CANCEL_NOTI_LIST_FAIL = 'CANCEL_NOTI_LIST_FAIL'
export const CANCEL_NOTI_LIST_RESET = 'CANCEL_NOTI_LIST_RESET'

export const ONE_WORKING_DAY_REQUEST = 'ONE_WORKING_DAY_REQUEST'
export const ONE_WORKING_DAY_SUCCESS = 'ONE_WORKING_DAY_SUCCESS'
export const ONE_WORKING_DAY_FAIL = 'ONE_WORKING_DAY_FAIL'
export const ONE_WORKING_DAY_RESET = 'ONE_WORKING_DAY_RESET'

export const TOMORROW_WORKING_DAY_REQUEST = 'TOMORROW_WORKING_DAY_REQUEST'
export const TOMORROW_WORKING_DAY_SUCCESS = 'TOMORROW_WORKING_DAY_SUCCESS'
export const TOMORROW_WORKING_DAY_FAIL = 'TOMORROW_WORKING_DAY_FAIL'
export const TOMORROW_WORKING_DAY_RESET = 'TOMORROW_WORKING_DAY_RESET'

export const FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCKTIME_REQUEST =
  'FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCKTIME_REQUEST'
export const FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCKTIME_SUCCESS =
  'FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCKTIME_SUCCESS'
export const FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCK_TIME_FAIL =
  'FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCK_TIME_FAIL'
export const FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCK_TIME_RESET =
  'FIND_CLOCK_BY_WORKDAY_ID_AND_CLOCK_TIME_RESET'

export const LIST_WORKING_DAYS_FOR_THIS_WEEK_REQUEST =
  'LIST_WORKING_DAYS_FOR_THIS_WEEK_REQUEST'
export const LIST_WORKING_DAYS_FOR_THIS_WEEK_SUCCESS =
  'LIST_WORKING_DAYS_FOR_THIS_WEEK_SUCCESS'
export const LIST_WORKING_DAYS_FOR_THIS_WEEK_FAIL =
  'LIST_WORKING_DAYS_FOR_THIS_WEEK_FAIL'

export const WORKING_DAY_DELETE_REQUEST = 'WORKING_DAY_DELETE_REQUEST'
export const WORKING_DAY_DELETE_SUCCESS = 'WORKING_DAY_DELETE_SUCCESS'
export const WORKING_DAY_DELETE_FAIL = 'WORKING_DAY_DELETE_FAIL'
export const WORKING_DAY_DELETE_RESET = 'WORKING_DAY_DELETE_RESET'

export const WORKING_DAY_DETAILS_REQUEST = 'WORKING_DAY_DETAILS_REQUEST'
export const WORKING_DAY_DETAILS_SUCCESS = 'WORKING_DAY_DETAILS_SUCCESS'
export const WORKING_DAY_DETAILS_FAIL = 'WORKING_DAY_DETAILS_FAIL'

export const MAKE_CLOCK_REQUEST = 'MAKE_CLOCK_REQUEST'
export const MAKE_CLOCK_SUCCESS = 'MAKE_CLOCK_SUCCESS'
export const MAKE_CLOCK_FAIL = 'MAKE_CLOCK_FAIL'
export const MAKE_CLOCK_SUCSSES_RESET = 'MAKE_CLOCK_SUCSSES_RESET'

export const MAKE_CLOCKS_REQUEST = 'MAKE_CLOCKS_REQUEST'
export const MAKE_CLOCKS_SUCCESS = 'MAKE_CLOCKS_SUCCESS'
export const MAKE_CLOCKS_FAIL = 'MAKE_CLOCKS_FAIL'
export const MAKE_CLOCKS_SUCSSES_RESET = 'MAKE_CLOCKS_SUCSSES_RESET'

export const CLOCK_LIST_REQUEST = 'CLOCK_LIST_REQUEST'
export const CLOCK_LIST_SUCCESS = 'CLOCK_LIST_SUCCESS'
export const CLOCK_LIST_FAIL = 'CLOCK_LIST_FAIL'

export const SEND_NotificationSMS_REQUEST = 'SEND_NotificationSMS_REQUEST'
export const SEND_NotificationSMS_SUCCESS = 'SEND_NotificationSMS_SUCCESS'
export const SEND_NotificationSMS_FAIL = 'SEND_NotificationSMS_FAIL'

export const CLOCK_DELETE_REQUEST = 'CLOCK_DELETE_REQUEST'
export const CLOCK_DELETE_SUCCESS = 'CLOCK_DELETE_SUCCESS'
export const CLOCK_DELETE_FAIL = 'CLOCK_DELETE_FAIL'
export const CLOCK_DELETE_RESET = 'CLOCK_DELETE_RESET'

export const MAKE_WORKINGDAY_RESET = 'MAKE_WORKINGDAY_RESET'

export const CONFIRM_TOR_REQUEST = 'CONFIRM_TOR_REQUEST'
export const CONFIRM_TOR_SUCCESS = 'CONFIRM_TOR_SUCCESS'
export const CONFIRM_TOR_FAIL = 'CONFIRM_TOR_FAIL'
export const CONFIRM_TOR_RESET = 'CONFIRM_TOR_RESET'

export const AVILABLE_WORKINGDAY_TORS_REQUEST =
  'AVILABLE_WORKINGDAY_TORS_REQUEST'
export const AVILABLE_WORKINGDAY_TORS_SUCCESS =
  'AVILABLE_WORKINGDAY_TORS_SUCCESS'
export const AVILABLE_WORKINGDAY_TORS_FAIL = 'AVILABLE_WORKINGDAY_TORS_FAIL'

export const TORIM_LIST_MY_REQUEST = 'TORIM_LIST_MY_REQUEST'
export const TORIM_LIST_MY_SUCCESS = 'TORIM_LIST_MY_SUCCESS'
export const TORIM_LIST_MY_FAIL = 'TORIM_LIST_MY_FAIL'
export const TORIM_LIST_MY_RESET = 'TORIM_LIST_MY_RESET'

export const CANCEL_TOR_REQUEST = 'CANCEL_TOR_REQUEST'
export const CANCEL_TOR_SUCCESS = 'CANCEL_TOR_SUCCESS'
export const CANCEL_TOR_FAIL = 'CANCEL_TOR_FAIL'
export const CANCEL_TOR_RESET = 'CONFIRM_TOR_RESET'

export const PAY_TOR_REQUEST = 'PAY_TOR_REQUEST'
export const PAY_TOR_SUCCESS = 'PAY_TOR_SUCCESS'
export const PAY_TOR_FAIL = 'PAY_TOR_FAIL'
export const PAY_TOR_RESET = 'PAY_TOR_RESET'

export const UNPAY_TOR_REQUEST = 'UNPAY_TOR_REQUEST'
export const UNPAY_TOR_SUCCESS = 'UNPAY_TOR_SUCCESS'
export const UNPAY_TOR_FAIL = 'UNPAY_TOR_FAIL'
export const UNPAY_TOR_RESET = 'UNPAY_TOR_RESET'

export const SEND_SMS_TOR_REQUEST = 'SEND_SMS_TOR_REQUEST'
export const SEND_SMS_TOR_SUCCESS = 'SEND_SMS_TOR_SUCCESS'
export const SEND_SMS_TOR_FAIL = 'SEND_SMS_TOR_FAIL'
export const SEND_SMS_TOR_RESET = 'SEND_SMS_TOR_RESET'

export const SEND_WHATSAPP_TOR_REQUEST = 'SEND_WHATSAPP_TOR_REQUEST'
export const SEND_WHATSAPP_TOR_SUCCESS = 'SEND_WHATSAPP_TOR_SUCCESS'
export const SEND_WHATSAPP_TOR_FAIL = 'SEND_WHATSAPP_TOR_FAIL'
export const SEND_WHATSAPP_TOR_RESET = 'SEND_WHATSAPP_TOR_RESET'

export const SEND_Cancel_WHATSAPP_TOR_REQUEST =
  'SEND_Cancel_WHATSAPP_TOR_REQUEST'
export const SEND_Cancel_WHATSAPP_TOR_SUCCESS =
  'SEND_Cancel_WHATSAPP_TOR_SUCCESS'
export const SEND_Cancel_WHATSAPP_TOR_FAIL = 'SEND_Cancel_WHATSAPP_TOR_FAIL'

export const SEND_Cancel_SMS_TOR_REQUEST = 'SEND_Cancel_SMS_TOR_REQUEST'
export const SEND_Cancel_SMS_TOR_SUCCESS = 'SEND_Cancel_SMS_TOR_SUCCESS'
export const SEND_Cancel_SMS_TOR_FAIL = 'SEND_Cancel_SMS_TOR_FAIL'
export const SEND_Cancel_SMS_TOR_RESET = 'SEND_Cancel_SMS_TOR_RESET'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

export const CLOCK_LIST_FOR_TODAY_REQUEST = 'CLOCK_LIST_FOR_TODAY_REQUEST'
export const CLOCK_LIST_FOR_TODAY_SUCCESS = 'CLOCK_LIST_FOR_TODAY_SUCCESS'
export const CLOCK_LIST_FOR_TODAY_FAIL = 'CLOCK_LIST_FOR_TODAY_FAIL'

export const CLOCK_LIST_FOR_THIS_WORK_DAY_REQUEST =
  'CLOCK_LIST_FOR_THIS_WORK_DAY_REQUEST'
export const CLOCK_LIST_FOR_THIS_WORK_DAY_SUCCESS =
  'CLOCK_LIST_FOR_THIS_WORK_DAY_SUCCESS'
export const CLOCK_LIST_FOR_THIS_WORK_DAY_FAIL =
  'CLOCK_LIST_FOR_THIS_WORK_DAY_FAIL'

export const CLOCK_LIST_FOR_THIS_MONTH_REQUEST =
  'CLOCK_LIST_FOR_THIS_MONTH_REQUEST'
export const CLOCK_LIST_FOR_THIS_MONTH_SUCCESS =
  'CLOCK_LIST_FOR_THIS_MONTH_SUCCESS'
export const CLOCK_LIST_FOR_THIS_MONTH_FAIL = 'CLOCK_LIST_FOR_THIS_MONTH_FAIL'

export const CLOCK_LIST_FOR_THIS_WEEK_REQUEST =
  'CLOCK_LIST_FOR_THIS_WEEK_REQUEST'
export const CLOCK_LIST_FOR_THIS_WEEK_SUCCESS =
  'CLOCK_LIST_FOR_THIS_WEEK_SUCCESS'
export const CLOCK_LIST_FOR_THIS_WEEK_FAIL = 'CLOCK_LIST_FOR_THIS_WEEK_FAIL'

export const ADMIN_CREATE_REPORT_REQUEST = 'ADMIN_CREATE_REPORT_REQUEST'
export const ADMIN_CREATE_REPORT_SUCCESS = 'ADMIN_CREATE_REPORT_SUCCESS'
export const ADMIN_CREATE_REPORT_FAIL = 'ADMIN_CREATE_REPORT_FAIL'

export const ADMIN_CREATE_REPORT_FOR_WEEK_REQUEST =
  'ADMIN_CREATE_REPORT_FOR_WEEK_REQUEST'
export const ADMIN_CREATE_REPORT_FOR_WEEK_SUCCESS =
  'ADMIN_CREATE_REPORT_FOR_WEEK_SUCCESS'
export const ADMIN_CREATE_REPORT_FOR_WEEK_FAIL =
  'ADMIN_CREATE_REPORT_FOR_WEEK_FAIL'

export const ADMIN_CREATE_REPORT_FOR_MONTH_REQUEST =
  'ADMIN_CREATE_REPORT_FOR_MONTH_REQUEST'
export const ADMIN_CREATE_REPORT_FOR_MONTH_SUCCESS =
  'ADMIN_CREATE_REPORT_FOR_MONTH_SUCCESS'
export const ADMIN_CREATE_REPORT_FOR_MONTH_FAIL =
  'ADMIN_CREATE_REPORT_FOR_MONTH_FAIL'

export const DAILY_REPORTS_LIST_REQUEST = 'DAILY_REPORTS_LIST_REQUEST'
export const DAILY_REPORTS_LIST_SUCCESS = 'DAILY_REPORTS_LIST_SUCCESS'
export const DAILY_REPORTS_LIST_FAIL = 'DAILY_REPORTS_LIST_FAIL'

export const INITIAL_PASSWORD_REQUEST = 'INITIAL_PASSWORD_REQUEST'
export const INITIAL_PASSWORD_SUCCESS = 'INITIAL_PASSWORD_SUCCESS'
export const INITIAL_PASSWORD_FAIL = 'INITIAL_PASSWORD_FAIL'

export const Weekly_REPORTS_LIST_REQUEST = 'Weekly_REPORTS_LIST_REQUEST'
export const Weekly_REPORTS_LIST_SUCCESS = 'Weekly_REPORTS_LIST_SUCCESS'
export const Weekly_REPORTS_LIST_FAIL = 'Weekly_REPORTS_LIST_FAIL'

export const MONTHLY_REPORTS_LIST_REQUEST = 'MONTHLY_REPORTS_LIST_REQUEST'
export const MONTHLY_REPORTS_LIST_SUCCESS = 'MONTHLY_REPORTS_LIST_SUCCESS'
export const MONTHLY_REPORTS_LIST_FAIL = 'MONTHLY_REPORTS_LIST_FAIL'

export const GET_REPORT_DEETS_BY_ID_REQUEST = 'GET_REPORT_DEETS_BY_ID_REQUEST'
export const GET_REPORT_DEETS_BY_ID_SUCCESS = 'GET_REPORT_DEETS_BY_ID_SUCCESS'
export const GET_REPORT_DEETS_BY_ID_FAIL = 'GET_REPORT_DEETS_BY_ID_FAIL'

export const CREATE_NEW_TIPUL_REQUEST = 'CREATE_NEW_TIPUL_REQUEST'
export const CREATE_NEW_TIPUL_SUCCESS = 'CREATE_NEW_TIPUL_SUCCESS'
export const CREATE_NEW_TIPUL_FAIL = 'CREATE_NEW_TIPUL_FAIL'

export const SUGEI_TIPULIM_LIST_REQUEST = 'SUGEI_TIPULIM_LIST_REQUEST'
export const SUGEI_TIPULIM_LIST_SUCCESS = 'SUGEI_TIPULIM_LIST_SUCCESS'
export const SUGEI_TIPULIM_LIST_FAIL = 'SUGEI_TIPULIM_LIST_FAIL'

export const GET_TIPUL_DEETS_REQUEST = 'GET_TIPUL_DEETS_REQUEST'
export const GET_TIPUL_DEETS_SUCCESS = 'GET_TIPUL_DEETS_SUCCESS'
export const GET_TIPUL_DEETS_FAIL = 'GET_TIPUL_DEETS_FAIL'

export const TIPUL_DELETE_REQUEST = 'TIPUL_DELETE_REQUEST'
export const TIPUL_DELETE_SUCCESS = 'TIPUL_DELETE_SUCCESS'
export const TIPUL_DELETE_FAIL = 'TIPUL_DELETE_FAIL'

export const TIPUL_UPDATE_REQUEST = 'TIPUL_UPDATE_REQUEST'
export const TIPUL_UPDATE_SUCCESS = 'TIPUL_UPDATE_SUCCESS'
export const TIPUL_UPDATE_FAIL = 'TIPUL_UPDATE_FAIL'
export const TIPUL_UPDATE_RESET = 'TIPUL_UPDATE_RESET'

export const ONE_USER_SEARCH_REQUEST = 'ONE_USER_SEARCH_REQUEST'
export const ONE_USER_SEARCH_SUCCESS = 'ONE_USER_SEARCH_SUCCESS'
export const ONE_USER_SEARCH_FAIL = 'ONE_USER_SEARCH_FAIL'
export const ONE_USER_SEARCH_RESET = 'ONE_USER_SEARCH_RESET'

export const POTENTIAL_USERS_REQUEST = 'POTENTIAL_USERS_REQUEST'
export const POTENTIAL_USERS_SUCCESS = 'POTENTIAL_USERS_SUCCESS'
export const POTENTIAL_USERS_FAIL = 'POTENTIAL_USERS_FAIL'
export const POTENTIAL_USERS_RESET = 'POTENTIAL_USERS_RESET'

export const AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_HALF_TIPUL_LIST_REQUEST =
  'AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_HALF_TIPUL_LIST_REQUEST'
export const AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_HALF_TIPUL_LIST_SUCCESS =
  'AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_HALF_TIPUL_LIST_SUCCESS'
export const AAVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_HALF_TIPUL_LIST_FAIL =
  'AAVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_HALF_TIPUL_LIST_FAIL'

export const AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_TIPUL_LIST_REQUEST =
  'AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_TIPUL_LIST_REQUEST'
export const AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_TIPUL_LIST_SUCCESS =
  'AVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_TIPUL_LIST_SUCCESS'
export const AAVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_TIPUL_LIST_FAIL =
  'AAVILABLE_WORKINGDAY_TORS_FOR_ONE_HOUR_TIPUL_LIST_FAIL'

export const USER_UPDATE_COMMENTS_FOR_TIPUL_REQUEST =
  'USER_UPDATE_COMMENTS_FOR_TIPUL_REQUEST'
export const USER_UPDATE_COMMENTS_FOR_TIPUL_SUCCESS =
  'USER_UPDATE_COMMENTS_FOR_TIPUL_SUCCESS'
export const USER_DETAILS_COMMENTS_FOR_TIPUL_SUCCESS =
  'USER_DETAILS_COMMENTS_FOR_TIPUL_SUCCESS'
export const USER_DETAILS_COMMENTS_FOR_TIPUL_RESET =
  'USER_DETAILS_COMMENTS_FOR_TIPUL_RESET'
export const USER_UPDATE_COMMENTS_FOR_TIPUL_FAIL =
  'USER_UPDATE_COMMENTS_FOR_TIPUL_FAIL'
