export const BUSINESS_DETAILS_REQUEST = 'BUSINESS_DETAILS_REQUEST'
export const BUSINESS_DETAILS_SUCCESS = 'BUSINESS_DETAILS_SUCCESS'
export const BUSINESS_DETAILS_FAIL = 'BUSINESS_DETAILS_FAIL'

export const ADMIN_NAME_REQUEST = 'ADMIN_NAME_REQUEST'
export const ADMIN_NAME_SUCCESS = 'ADMIN_NAME_SUCCESS'
export const ADMIN_NAME_FAIL = 'ADMIN_NAME_FAIL'

export const ALL_BUSINESS_REQUEST = 'ALL_BUSINESS_REQUEST'
export const ALL_BUSINESS_SUCCESS = 'ALL_BUSINESS_SUCCESS'
export const ALL_BUSINESS_FAIL = 'ALL_BUSINESS_FAIL'

export const OPEN_NEW_BIZZ_REQUEST = 'OPEN_NEW_BIZZ_REQUEST'
export const OPEN_NEW_BIZZ_SUCCESS = 'OPEN_NEW_BIZZ_SUCCESS'
export const OPEN_NEW_BIZZ_FAIL = 'OPEN_NEW_BIZZ_FAIL'
export const OPEN_NEW_BIZZ_RESET = 'OPEN_NEW_BIZZ_RESET'

export const BUSINESS_DETAILS_FORNAV_REQUEST = 'BUSINESS_DETAILS_FORNAV_REQUEST'
export const BUSINESS_DETAILS_FORNAV_SUCCESS = 'BUSINESS_DETAILS_FORNAV_SUCCESS'
export const BUSINESS_DETAILS_FORNAV_FAIL = 'BUSINESS_DETAILS_FORNAV_FAIL'

export const WORKERS_LIST_REQUEST = 'WORKERS_LIST_REQUEST'
export const WORKERS_LIST_SUCCESS = 'WORKERS_LIST_SUCCESS'
export const WORKERS_LIST_FAIL = 'WORKERS_LIST_FAIL'

export const TREATMENTS_LIST_REQUEST = 'TREATMENTS_LIST_REQUEST'
export const TREATMENTS_LIST_SUCCESS = 'TREATMENTS_LIST_SUCCESS'
export const TREATMENTS_LIST_FAIL = 'TREATMENTS_LIST_FAIL'

export const BUSINESS_USER_REGISTER_REQUEST = 'BUSINESS_USER_REGISTER_REQUEST'
export const BUSINESS_USER_REGISTER_SUCCESS = 'BUSINESS_USER_REGISTER_SUCCESS'
export const BUSINESS_USER_REGISTER_FAIL = 'BUSINESS_USER_REGISTER_FAIL'

export const BUSINESS_USER_LOGIN_REQUEST = 'BUSINESS_USER_LOGIN_REQUEST'
export const BUSINESS_USER_LOGIN_SUCCESS = 'BUSINESS_USER_LOGIN_SUCCESS'
export const BUSINESS_USER_LOGIN_FAIL = 'BUSINESS_USER_LOGIN_FAIL'

export const ADMIN_SIDE_REGISTER_REQUEST = 'ADMIN_SIDE_REGISTER_REQUEST'
export const ADMIN_SIDE_REGISTER_SUCCESS = 'ADMIN_SIDE_REGISTER_SUCCESS'
export const ADMIN_SIDE_REGISTER_FAIL = 'ADMIN_SIDE_REGISTER_FAIL'
export const ADMIN_SIDE_REGISTER_RESET = 'ADMIN_SIDE_REGISTER_RESET'

export const BUSINESS_USER_LIST_REQUEST = 'BUSINESS_USER_LIST_REQUEST'
export const BUSINESS_USER_LIST_SUCCESS = 'BUSINESS_USER_LIST_SUCCESS'
export const BUSINESS_USER_LIST_FAIL = 'BUSINESS_USER_LIST_FAIL'
export const BUSINESS_USER_LIST_RESET = 'BUSINESS_USER_LIST_RESET'

export const BUSINESS_SETTINGS_REQUEST = 'BUSINESS_SETTINGS_REQUEST'
export const BUSINESS_SETTINGS_SUCCESS = 'BUSINESS_SETTINGS_SUCCESS'
export const BUSINESS_SETTINGS_FAIL = 'BUSINESS_SETTINGS_FAIL'

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL'

export const UPDATE_DESIGN_SETTINGS_REQUEST = 'UPDATE_DESIGN_SETTINGS_REQUEST'
export const UPDATE_DESIGN_SETTINGS_SUCCESS = 'UPDATE_DESIGN_SETTINGS_SUCCESS'
export const UPDATE_DESIGN_SETTINGS_FAIL = 'UPDATE_DESIGN_SETTINGS_FAIL'

export const GET_LOCATION_GEO_REQUEST = 'GET_LOCATION_GEO_REQUEST'
export const GET_LOCATION_GEO_SUCCESS = 'GET_LOCATION_GEO_SUCCESS'
export const GET_LOCATION_GEO_FAIL = 'GET_LOCATION_GEO_FAIL'
export const GET_LOCATION_GEO_RESET = 'GET_LOCATION_GEO_RESET'

export const BUSINESS_DETAILS_FOR_DESIGN_REQUEST =
  'BUSINESS_DETAILS_FOR_DESIGN_REQUEST'
export const BUSINESS_DETAILS_FOR_DESIGN_SUCCESS =
  'BUSINESS_DETAILS_FOR_DESIGN_SUCCESS'
export const BUSINESS_DETAILS_FOR_DESIGN_FAIL =
  'BUSINESS_DETAILS_FOR_DESIGN_FAIL'
