import React from 'react'
import SmartCounter from './SmartCounter'

const OpenVirtualBarberShopBTN = (props) => (
  <div className='openVirtualBarberShopImage2'>
    <div>
      <br />
      <br />
      <span id='TRY_US_NOW'> מתלבט ? פשוט נסה אותנו </span>
      <div
        onClick={() => props.history.push(`/createnewbusiness`)}
        id='formoredeetsBtn2'
      >
        התחל תקופת ניסיון
      </div>{' '}
    </div>{' '}
  </div>
)

export default OpenVirtualBarberShopBTN
