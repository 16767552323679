const TorArr = [
  'תור',
  'מור',
  'כפתור',
  'מטול',
  'מסור',
  'נכון',
  'הכל',
  'התור',
  'מטור',
  'בתור',
  'שור',
  'סור',
  'קור',
  'טור',
  'טוב',
  'טוסט',
]
export default TorArr
