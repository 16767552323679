//מתייחס להיום אך גם כמילת ייחוס ליום חמישי..ליום ראשון לדוגמא

const LeHaiomArr = [
  'יון',
  'היום',
  'להיום',
  'ביום',
  'ביון',
  'מיון',
  'ליון',
  'ליום',
  'יום',
  'כיום',
  'מהיום',
]
export default LeHaiomArr
