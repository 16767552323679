import React, { useState, useEffect } from 'react'
import './CreateNewBussinesPage.css'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import {
  FindGeoLocationByName,
  OpenNewBizzAction,
} from '../actions/BuissnesActions/Buissnes_User_Actions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import TDcolorBinder from '../components/Business_Components/BusinessSettingsDesignComponents/TDcolorBinder'

export const CreateNewBussinesPage = ({ history }) => {
  const dispatch = useDispatch()
  //selectors
  const OpenNewBizz = useSelector((state) => state.OpenNewBizz)
  const { loading, business, success, error } = OpenNewBizz
  const GetBusinessGeo = useSelector((state) => state.GetBusinessGeo)
  const { loadingGeo, businessGeo, successGeo, errorGeo } = GetBusinessGeo
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  //states
  const [show1, setshow1] = useState(true)
  const [show2, setshow2] = useState(false)
  const [show3, setshow3] = useState(false)
  const [name, setName] = useState('')
  const [adress, setAdress] = useState('')
  const [phone, setphone] = useState('')
  const [lat, setlat] = useState('')
  const [lng, setlng] = useState('')
  const [ShowTDcolorBinder, setShowTDcolorBinder] = useState(false)
  const [color, setcolor] = useState(null)
  const [logo, setLogo] = useState(null)
  const [BusinessPic, setBusinessPic] = useState(null)
  //stage3 states
  const [
    sendSMSClientSide_CheckBox_state,
    setsendSMSClientSide_CheckBox_state,
  ] = useState(false)

  const [
    sendWhatsappClientSide_CheckBox_state,
    setsendWhatsappClientSide_CheckBox_state,
  ] = useState(false)
  const [sendSMSAdminSide_CheckBox_state, setsendSMSAdminSide_CheckBox_state] =
    useState(false)
  const [
    sendWhatsappAdminSide_CheckBox_state,
    setsendWhatsappAdminSide_CheckBox_state,
  ] = useState(false)
  const [
    sendSMSClientSideCancel_CheckBox_state,
    setsendSMSClientSideCancel_CheckBox_state,
  ] = useState(false)
  const [
    sendWhatsappClientSideCancel_CheckBox_state,
    setsendWhatsappClientSideCancel_CheckBox_state,
  ] = useState(false)
  const [
    sendSMSAdminSideCancel_CheckBox_state,
    setsendSMSAdminSideCancel_CheckBox_state,
  ] = useState(false)
  const [
    sendWhatsappAdminSideCancel_CheckBox_state,
    setsendWhatsappAdminSideCancel_CheckBox_state,
  ] = useState(false)
  const [
    sendSMSClientSideReminder_CheckBox_state,
    setsendSMSClientSideReminder_CheckBox_state,
  ] = useState(false)
  const [
    sendWhatsappClientSideReminder_CheckBox_state,
    setsendWhatsappClientSideReminder_CheckBox_state,
  ] = useState(false)
  const [
    sendSMSAdminSideReminder_CheckBox_state,
    setsendSMSAdminSideReminder_CheckBox_state,
  ] = useState(false)
  const [
    sendWhatsappAdminSideReminder_CheckBox_state,
    setsendWhatsappAdminSideReminder_CheckBox_state,
  ] = useState(false)
  const [BusinessNotificationsTime, setBusinessNotificationsTime] = useState('')

  //Functions

  const checkIfAdressIsValid = () => {
    dispatch(FindGeoLocationByName(adress))
  }
  const MoveTostage1 = () => {
    setshow1(true)
    setshow2(false)
    setshow3(false)
  }
  const MoveTostage2 = () => {
    if (businessGeo && successGeo == true && businessGeo.results[0]) {
      setshow1(false)
      setshow2(true)
      setshow3(false)
    }
  }
  const canMoveTostage3 = () => {
    if (businessGeo && logo != null && BusinessPic != null && color != null) {
      MoveTostage3()
    }
  }
  const MoveTostage3 = () => {
    setshow1(false)
    setshow2(false)
    setshow3(true)
  }

  const CreateNewBussinesHandler = () => {
    console.log(`action`)
    dispatch(
      OpenNewBizzAction(
        userInfo._id,
        name,
        phone,
        { name: adress, lat: lat, lng: lng },
        color,
        logo,
        BusinessPic,
        {
          sendSMSClientSide_CheckBox_state: sendSMSClientSide_CheckBox_state,
          sendWhatsappClientSide_CheckBox_state:
            sendWhatsappClientSide_CheckBox_state,
          sendSMSAdminSide_CheckBox_state: sendSMSAdminSide_CheckBox_state,
          sendWhatsappAdminSide_CheckBox_state:
            sendWhatsappAdminSide_CheckBox_state,
          sendSMSClientSideCancel_CheckBox_state:
            sendSMSClientSideCancel_CheckBox_state,
          sendWhatsappClientSideCancel_CheckBox_state:
            sendWhatsappClientSideCancel_CheckBox_state,
          sendSMSAdminSideCancel_CheckBox_state:
            sendSMSAdminSideCancel_CheckBox_state,
          sendWhatsappAdminSideCancel_CheckBox_state:
            sendWhatsappAdminSideCancel_CheckBox_state,
          sendSMSClientSideReminder_CheckBox_state:
            sendSMSClientSideReminder_CheckBox_state,
          sendWhatsappClientSideReminder_CheckBox_state:
            sendWhatsappClientSideReminder_CheckBox_state,
          sendSMSAdminSideReminder_CheckBox_state:
            sendSMSAdminSideReminder_CheckBox_state,
          sendWhatsappAdminSideReminder_CheckBox_state:
            sendWhatsappAdminSideReminder_CheckBox_state,
          BusinessNotificationsTime: BusinessNotificationsTime,
        }
      )
    )
  }
  useEffect(() => {
    if (!userInfo) {
      history.push(`/login`)
    }
  }, [history, userInfo])
  useEffect(() => {
    if (business && success) {
      history.push(`/business/${business.newBizId}`)
    }
  }, [business, success])
  //UseEffects
  useEffect(() => {
    if (businessGeo && successGeo == true && businessGeo.results[0]) {
      console.log(
        businessGeo.results[0].geometry.location.lat,
        businessGeo.results[0].geometry.location.lng
      )
      setlat(businessGeo.results[0].geometry.location.lat)
      setlng(businessGeo.results[0].geometry.location.lng)
      MoveTostage2()
    } else if (errorGeo == true && successGeo == false && !businessGeo) {
      setTimeout(
        () =>
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            html: `שגיאה-המיקום שהזנת אינו נמצא`,
            showConfirmButton: false,
            timerProgressBar: true,
            backdrop: `rgba(0,0,0,0.0)`,
            allowOutsideClick: true,
            timer: 4500,
            toast: true,
          }),
        2000
      )
    }
  }, [businessGeo, successGeo, errorGeo])
  return (
    <>
      {ShowTDcolorBinder && (
        <TDcolorBinder
          close={() => setShowTDcolorBinder(false)}
          change={(color) => setcolor(color)}
          color={`black+blue`}
        />
      )}
      {userInfo ? (
        <>
          <Form id='msform'>
            <ul id='progressbar'>
              <li
                onClick={() => canMoveTostage3()}
                className={show3 == true ? 'active' : ''}
              >
                הגדרות מערכת
              </li>
              <li
                onClick={() => MoveTostage2()}
                className={show2 == true ? 'active' : ''}
              >
                הגדרות עיצוב
              </li>
              <li
                onClick={() => MoveTostage1()}
                className={show1 == true ? 'active' : ''}
              >
                הגדרות עסק
              </li>
            </ul>
            <fieldset className={show1 == true ? 'visible' : 'unvisible'}>
              <h2 class='fs-title'>הגדרת עסק חדש</h2>

              <Form.Group controlId='name'>
                <h4 class='fs-subtitle'>שם העסק </h4>

                <Form.Control
                  type='name'
                  placeholder='הכנס את שם העסק'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <h4 class='fs-subtitle'>כתובת העסק </h4>

              <Form.Group controlId='name'>
                <Form.Control
                  type='name'
                  placeholder='הכנס את כתובת העסק'
                  value={adress}
                  onChange={(e) => setAdress(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <h4 class='fs-subtitle'> פרטי התקשרות </h4>

              <Form.Group controlId='name'>
                <Form.Control
                  type='phone'
                  placeholder='הכנס את הנייד שלך'
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>

              <input
                onClick={() => {
                  checkIfAdressIsValid()
                }}
                type='button'
                name='next'
                class='next action-button'
                value='לשלב הבא'
              />
            </fieldset>
          </Form>

          <Form id='msform'>
            <fieldset className={show2 == true ? 'visible' : 'unvisible'}>
              <h2 class='fs-title'>הגדרות עיצוב</h2>
              <h4 class='fs-subtitle'>צבעים </h4>
              <Form.Group controlId='name'>
                <Form.Control
                  type='name'
                  placeholder='בחר את הצבעים שלך'
                  value={
                    color === null
                      ? 'בחר את הצבעים שלך'
                      : color === 'black+blue'
                      ? 'כחול + שחור'
                      : color === 'black+white'
                      ? 'שחור + לבן'
                      : 'צבע נבחר'
                  }
                  onClick={(e) => setShowTDcolorBinder(true)}
                  required
                ></Form.Control>
              </Form.Group>
              <h4 class='fs-subtitle'> לוגו </h4>

              <Form.Group controlId='name'>
                <Form.Control
                  type='url'
                  placeholder='הכנס את כתובת הלוגו שלך'
                  value={logo}
                  onChange={(e) => setLogo(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <h4 class='fs-subtitle'> תמונה </h4>

              <Form.Group controlId='name'>
                <Form.Control
                  type='url'
                  placeholder='הכנס את כתובת התמונה הראשית שלך'
                  value={BusinessPic}
                  onChange={(e) => setBusinessPic(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>

              <input
                onClick={() => {
                  canMoveTostage3()
                }}
                type='button'
                name='next'
                class='next action-button'
                value='לשלב הבא'
              />
            </fieldset>
          </Form>

          <Form id='msform3'>
            <fieldset className={show3 == true ? 'visible' : 'unvisible'}>
              <h2 class='fs-title'>הגדרות מערכת</h2>
              <h4 class='fs-subtitle'>צבעים </h4>
              <Table
                bordered
                responsive
                className='whiteme'
                id='tablewhiteUserLIST_table'
              >
                <thead>
                  <tr id='tableheadlines'>
                    <th id='widthforUserListDelete'>SMS</th>
                    <th id='widthforUserListDelete'>Whatsapp</th>

                    <th>פעולות</th>
                  </tr>
                </thead>
                <tbody id='centertext'>
                  <td>
                    {' '}
                    <input
                      onClick={() =>
                        setsendSMSClientSide_CheckBox_state(
                          !sendSMSClientSide_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendSMSClientSide_CheckBox_state}
                    />
                  </td>
                  <td>
                    <input
                      onClick={() =>
                        setsendWhatsappClientSide_CheckBox_state(
                          !sendWhatsappClientSide_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendWhatsappClientSide_CheckBox_state}
                    />
                  </td>
                  <td>
                    הודעת אישור תור לנייד של הלקוח בעת קביעת תור ע"י הלקוח
                  </td>
                </tbody>
                <tbody id='centertext'>
                  <td>
                    {' '}
                    <input
                      onClick={() =>
                        setsendSMSAdminSide_CheckBox_state(
                          !sendSMSAdminSide_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendSMSAdminSide_CheckBox_state}
                    />
                  </td>
                  <td>
                    <input
                      onClick={() =>
                        setsendWhatsappAdminSide_CheckBox_state(
                          !sendWhatsappAdminSide_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendWhatsappAdminSide_CheckBox_state}
                    />
                  </td>
                  <td>
                    הודעת אישור תור לנייד של הלקוח בעת קביעת תור ע"י המנהל
                  </td>
                </tbody>
                <tbody id='centertext'>
                  <td>
                    {' '}
                    <input
                      onClick={() =>
                        setsendSMSClientSideCancel_CheckBox_state(
                          !sendSMSClientSideCancel_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendSMSClientSideCancel_CheckBox_state}
                    />
                  </td>
                  <td>
                    <input
                      onClick={() =>
                        setsendWhatsappClientSideCancel_CheckBox_state(
                          !sendWhatsappClientSideCancel_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendWhatsappClientSideCancel_CheckBox_state}
                    />
                  </td>
                  <td>
                    הודעת ביטול תור לנייד של הלקוח בעת ביטול תור ע"י הלקוח
                  </td>
                </tbody>
                <tbody id='centertext'>
                  <td>
                    {' '}
                    <input
                      onClick={() =>
                        setsendSMSAdminSideCancel_CheckBox_state(
                          !sendSMSAdminSideCancel_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendSMSAdminSideCancel_CheckBox_state}
                    />
                  </td>
                  <td>
                    <input
                      onClick={() =>
                        setsendWhatsappAdminSideCancel_CheckBox_state(
                          !sendWhatsappAdminSideCancel_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendWhatsappAdminSideCancel_CheckBox_state}
                    />
                  </td>
                  <td>
                    הודעת ביטול תור לנייד של הלקוח בעת ביטול תור ע"י המנהל
                  </td>
                </tbody>
                <tbody id='centertext'>
                  <td>
                    {' '}
                    <input
                      onClick={() =>
                        setsendSMSClientSideReminder_CheckBox_state(
                          !sendSMSClientSideReminder_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendSMSClientSideReminder_CheckBox_state}
                    />
                  </td>
                  <td>
                    <input
                      onClick={() =>
                        setsendWhatsappClientSideReminder_CheckBox_state(
                          !sendWhatsappClientSideReminder_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendWhatsappClientSideReminder_CheckBox_state}
                    />
                  </td>
                  <td>התראת תזכורת לנייד של הלקוח לאחר קביעת תור ע"י הלקוח</td>
                </tbody>
                <tbody id='centertext'>
                  <td>
                    {' '}
                    <input
                      onClick={() =>
                        setsendSMSAdminSideReminder_CheckBox_state(
                          !sendSMSAdminSideReminder_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendSMSAdminSideReminder_CheckBox_state}
                    />
                  </td>
                  <td>
                    <input
                      onClick={() =>
                        setsendWhatsappAdminSideReminder_CheckBox_state(
                          !sendWhatsappAdminSideReminder_CheckBox_state
                        )
                      }
                      type='checkbox'
                      checked={sendWhatsappAdminSideReminder_CheckBox_state}
                    />
                  </td>
                  <td>התראת תזכורת לנייד של הלקוח לאחר קביעת תור ע"י מנהל</td>
                </tbody>
              </Table>

              <input
                onClick={() => {
                  CreateNewBussinesHandler()
                }}
                type='button'
                name='next'
                class='next action-button'
                value='אישור'
              />
            </fieldset>
          </Form>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
