const KevaArr = [
  'קבע',
  'כבד',
  'קןבע',
  'קבעת',
  'תקבע',
  'קובעת',
  'תקווה',
  'נקבע',
  'קובע',
  'קבענו',
  'קובע',
  'אקבע',
  'מה',
  'אבל',
  'בעל',
  'כבר',
  'וואלה',
  'כבעל',
  'כפתור',
  'טובל',
]
export default KevaArr
