import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listOfWorkers } from '../../../actions/BuissnesActions/Buissnes_User_Actions'
const MakeAppointmentBG = (props) => {
  const dispatch = useDispatch()
  const BusinessWorkersList = useSelector((state) => state.BusinessWorkersList)
  const { workers, workerserror, workersloading } = BusinessWorkersList
  useEffect(() => {
    dispatch(listOfWorkers(props.businessID))
  }, [])

  useEffect(() => {
    if (workers && workers.length) {
      console.log(workers.length)
      console.log(workers)
      console.log(workers[0])
    }
  }, [workers])

  return (
    <>
      <LinkContainer
        to={
          !workerserror && !workersloading && workers && workers.length > 1
            ? `/business/${props.businessID}/picksapar`
            : !workerserror && !workersloading && workers[0]
            ? `/business/${props.businessID}/maketor/${workers[0]._id}`
            : `/business/${props.businessID}/picksapar`
        }
      >
        <div className='button_container'>
          <i
            id={
              props.websiteColors === 'black+blue'
                ? 'arrowleft'
                : props.websiteColors === 'black+white'
                ? 'arrowleftblackandwhite'
                : 'dontdisplay'
            }
            className='fas fa-long-arrow-alt-right'
          ></i>

          <button
            className={
              props.websiteColors === 'black+blue'
                ? 'maketorbtn'
                : props.websiteColors === 'black+white'
                ? 'maketorbtnblackwhite'
                : 'maketorbtn'
            }
          >
            <span>קבע תור</span>
          </button>
          <i
            id={
              props.websiteColors === 'black+blue'
                ? 'arrowright'
                : props.websiteColors === 'black+white'
                ? 'arrowrightblackandwhite'
                : 'dontdisplay'
            }
            className='fas fa-long-arrow-alt-left'
          ></i>
        </div>
      </LinkContainer>
    </>
  )
}
export default MakeAppointmentBG
