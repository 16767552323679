///****NEED TO WRITE LIVE VALIFATIONS FOR EMAIL AND PASSWORD */
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'
import FormContainer from '../../../components/FormContainer'
import {
  login,
  Create15PortForResetPASSWORD_withPhone,
  Send_RESET_PASS_SMS,
} from '../../../actions/userActions'
import './LoginScreen.css'
import Swal from 'sweetalert2'
import Verfy4Digits from '../../../components/Verfy4Digits/Verfy4Digits.js'
import { SEND_RESET_SMS_TOR_RESET } from '../../../constants/userConstants'
import { getBuissnesDetails } from '../../../actions/BuissnesActions/Buissnes_User_Actions'
const BusinessLoginScreen = ({ location, history, match }) => {
  const BusinessId = window.location.pathname.split('/')[2]
  let URLL = `${BusinessId}`
  localStorage.setItem('roll-back-business', URLL)
  const BussinesID = match.params.id
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [LoginWithPhone, setLoginWithPhone] = useState(true)
  const [PhoneToSendTo, SetPhoneToSendTo] = useState('')
  const [SHOW_ME_VARIFICATION, SetSHOW_ME_VARIFICATION] = useState('')
  const [word, setWord] = useState(false)

  const form = useRef()

  const dispatch = useDispatch()
  const GetBusinessDetails = useSelector((state) => state.GetBusinessDetails)
  const {
    loading: BussinesLoading,
    business,
    success,
    error: BussinesError,
  } = GetBusinessDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const BUILD_RESET_PAGE = useSelector((state) => state.BUILD_RESET_PAGE)
  const {
    loading: pageloading,
    page,
    success: pagesuccess,
    error: pageerror,
  } = BUILD_RESET_PAGE

  const BUILD_RESET_PAGE_FOR_PHONE = useSelector(
    (state) => state.BUILD_RESET_PAGE_FOR_PHONE
  )
  const { pagePhone, loadingPhone, errorPhone, successPhone } =
    BUILD_RESET_PAGE_FOR_PHONE

  const SendTorSMS_RESET = useSelector((state) => state.SendTorSMS_RESET)
  const { loadingSendSMS, successSend, send } = SendTorSMS_RESET

  //const redirect = location.search ? location.search.split('=')[1] : '/'
  const redirect = `/business/${BussinesID}`

  useEffect(() => {
    dispatch(getBuissnesDetails(BussinesID))
  }, [dispatch])

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }

    if (successPhone) {
      let PAGEPHONE = pagePhone
      dispatch({ type: SEND_RESET_SMS_TOR_RESET })

      history.push(PAGEPHONE)
    }
    if (successSend) {
      SetSHOW_ME_VARIFICATION(true)
    }
    if (word) {
      setWord(false)
      SetSHOW_ME_VARIFICATION(false)
      dispatch(Create15PortForResetPASSWORD_withPhone(PhoneToSendTo))
      Swal.fire({
        title: 'קוד השחזור אומת בצלחה',
        text: 'מיד תועבר לדף לשחזור הסיסמה שלך',
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: false,
      })
    }
  }, [
    history,
    userInfo,
    redirect,
    pagesuccess,
    successPhone,
    successSend,
    word,
  ])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'שגיאה',
        text: `${error}`,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> הבנתי',
      })
    }
  }, [error])

  const submitHandler2 = (e) => {
    e.preventDefault()

    if (phone.length != 10 || phone.substring(0, 2) != '05') {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: 'error',
        title: ' !המספר אינו תקין ',
        text: ' יש להזין מספר נייד תקין בעל 10 ספרות וקידומת ישראלית',
      })
    } else {
      dispatch(login(phone, password)) //send to actions and fowerd as email needto be fixed to phone fron action
    }
  }

  const Swal_I_Forgot_My_Pass = () => {
    Swal.fire({
      title: 'שחזור סיסמה',
      text: `ניתן לשחזר את הסיסמה באמצעות הנייד   `,
      imageUrl: 'https://i.ibb.co/30z4Vsr/ezgif-com-gif-maker-15.gif',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'שחזור באמצעות הנייד',
      showConfirmButton: false,
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'שחזור באמצעות הנייד',
          text: `הזן את הנייד אליו ישלח קוד חד פעמי לשחזור הסיסמה  `,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'ביטול',
          confirmButtonText: 'שחזר סיסמה',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off',
          },
          preConfirm: async (phone) => {
            SetPhoneToSendTo(phone)
            return await fetch(`/api/search/phones/${phone}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText)
                } else {
                  /****here create a auth sms to phone  */
                  dispatch(Send_RESET_PASS_SMS(phone))
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`המספר שרשמת לא נמצא במערכת`)
              })
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              imageUrl: 'https://i.ibb.co/8sscqJ0/animation-300-kzzdqz4y.gif',
              title: `ההודעה  נשלח בהצלחה`,
              showConfirmButton: false,

              timer: 5000,
            })
            //)
          }
        })
      }
    })
  }

  const closemodel = () => {
    SetSHOW_ME_VARIFICATION(false)
  }

  return (
    <>
      {SHOW_ME_VARIFICATION && (
        <Verfy4Digits
          changeword={(word) => setWord(word)}
          close={() => closemodel()}
          send={send}
        />
      )}

      {LoginWithPhone && (
        <div class='login-box'>
          <FormContainer>
            {loading && <Loader />}
            <div id='centerme'>
              <Form onSubmit={submitHandler2} className='loginForm'>
                <h2 className='headlineme'>התחבר</h2>
                <div className='user-box'>
                  <Form.Group controlId='email'>
                    <Form.Control
                      className='form-control'
                      placeholder='נייד'
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value)
                      }}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div class='user-box'>
                  <Form.Group controlId='password'>
                    <Form.Control
                      className='form-control'
                      placeholder='סיסמה '
                      type='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div>
                  <btn
                    className='whitemeforgot'
                    id='signUp'
                    onClick={Swal_I_Forgot_My_Pass}
                  >
                    שחכתי סיסמה
                  </btn>
                </div>
                <Button type='submit' className='loginBTN'>
                  התחבר
                </Button>

                <Row className='py-3'>
                  <Col>
                    <div className='whiteme'>
                      לקוח חדש?{' '}
                      <Link id='signUp' to={`/business/${BussinesID}/register`}>
                        הירשם
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </FormContainer>
        </div>
      )}
    </>
  )
}

export default BusinessLoginScreen
