const TorimArr = [
  'תורים',
  'את',
  'מורים',
  'טורים',
  'לטורים',
  'התורים',
  'הטורים',
  'שורים',
  'סורים',
  'קוראים',
  'הקוראים',
  'השומרים',
  'הדובים',
  'הטובים',
  'הדברים',
  'פורים',
  'פורים',
  'דברים',
  'העבודה',
  'עבודה',
]
export default TorimArr
